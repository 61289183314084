<template>
  <v-card-text class="py-0">
    <v-timeline
      align-top
      dense
      v-for="(value, index) in all_updates"
      :key="index"
    >
      <br />
      <v-timeline-item color="pink" small v-if="value.version !== 'colors'">
        <v-layout pt-3>
          <v-flex xs1>
            <strong>{{ value.version }}</strong>
          </v-flex>
          <v-col md="12">
            <v-flex xs12 v-for="(v, i) in value.updates.updates" :key="i">
              <div class="update-item">
                <!-- <v-col md="12"> here </v-col> -->
                <strong>{{ i.toUpperCase() }}</strong>
                <div class="caption" v-for="(data, ind) in v" :key="ind">
                  <span v-if="typeof data === 'string'"
                    >{{ ++ind }}) {{ data }}
                  </span>
                  <span
                    v-else-if="typeof data === 'object'"
                    style="padding-left: 20px; display: block; color: red"
                  >
                    <div style="text-decoration: underline">
                      {{ data.version }}
                    </div>
                    <div v-for="(val, x) in data.updates" :key="x">
                      {{ x + 1 }}) {{ val }}
                    </div>
                  </span>
                  <!-- {{ ++ind }}. {{ data }} -->
                </div>
              </div>
            </v-flex>
          </v-col>
        </v-layout>
      </v-timeline-item>
    </v-timeline>
    <!-- <v-timeline
      align-top
      dense
      v-for="(value, index) in updatesData"
      :key="index"
    >
      <v-timeline-item color="pink" small>
        <v-layout pt-3>
          <v-flex xs1>
            <strong> {{ index }} <br /></strong>
          </v-flex>

          <v-flex xs12 v-for="(v, i) in value.updates" :key="i">
            <div class="update-item">
              <strong>{{ i }}</strong>
              <div class="caption" v-for="(data, di) in v" :key="di">
                {{ ++di }}{{ data }}
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-timeline-item>
    </v-timeline> -->
  </v-card-text>
</template>
<script>
import updatesData from "@/assets/json/updates.json";
export default {
  data() {
    return {
      updatesData,
      all_updates: [],
    };
  },
  mounted() {
    const keysSorted = Object.keys(updatesData).sort(function (a, b) {
      return b - a;
    });
    for (let i = 0; i < keysSorted.length; i++) {
      const obj = {};
      obj.version = keysSorted[i];
      obj.updates = updatesData[keysSorted[i]];
      this.all_updates.push(obj);
    }
  },
  methods: {
    // getAllUpdates() {
    //   const updatedata = this.updatesData;
    //   console.log("here", updatedata);
    // },
  },
};
</script>